import Select from "react-select";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import useAxios from "hook/useAxios";
import { useLogin } from "hook/useLogin";

const ModalAdd = ({modal,setModal,selectUser,delEvent}) => {

    const event = modal.event;
    const selectedUser = event.extendedProps.user

    // user info
    const def = selectedUser ? {
        id:selectedUser.id,
        name:selectedUser.name
    } : null;

    const [users,setUsers] = useState([]);
    const [data,setData] = useState(def);

    const {user} = useLogin();
    const axios=useAxios(user);

    const startTime = event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
    const endTime = event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});

    const time = `${startTime} - ${endTime}`;

    useEffect(()=>{
        const reqUrl = `/dashboard/user`;
        axios
            .get(reqUrl)
            .then(({data}) => 
            setUsers(data.res.filter(user => !user.admin))
        )
    },[]);

    let select2Data=[],value;
    if(users.length){
        // const defValue = {label:"Не вибрано",value:0}
        // select2Data = [defValue];
        select2Data = users.length && [...select2Data,...users.map(user => ({
            label: user.name,
            value: user.id
        }))]
        if(data){
            value = select2Data.filter(user=>user.value == data.id);
        }
    }

    const handleSelect = data =>{
        setData({id:data.value,name:data.name});
    }

    const save = () =>{
        selectUser({id:event.id,user:data});
        close();
    }

    const del = () =>{
        delEvent(event.id);
        close();
    }

    const close = () =>{
        setModal({...modal,show:false});
    }

    return (
        <Modal isOpen={modal.show} onClose={()=>close()}>
            <div className="mb-3"><h5>{time}</h5></div>
            <div className='d-flex gap-3 flex-column'>
                <div>
                    <Select
                    options={select2Data}
                    value={value}
                    onChange={handleSelect}
                    classNamePrefix="my-select"
                    placeholder={"Виберіть працівника"}
                    />
                </div>
                <div className="button"  onClick={()=>save()}>Змінити</div>
                <div className="button _none" onClick={()=>del()}>Видалити</div>
            </div>
        </Modal>
    )
}

export {ModalAdd}