import Calendar from "./AccountWorker/Calendar"

// import { useState } from "react"
// import Modal from "./Modal"
import { useLogin } from "hook/useLogin"

const AccountWorker = () =>{

    const {signOut,user} = useLogin();

    return (
        <>
            <div className="main-size m-auto justify-content-center">
                <div>
                    <h1>XLIB&COFFEE</h1>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
                    <div className="text-uppercase fw-bold">
                        Вітаннячко! {user.name}
                    </div>
                    <div className="icons _exit" onClick={()=>signOut()}></div>
                </div>
                <Calendar/>
            </div>
        </>
    )
}

export {AccountWorker}