
import React, {useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import ukLocale from '@fullcalendar/core/locales/uk';

import styled from 'styled-components';
import {Spinner} from 'react-bootstrap';

import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLogin } from 'hook/useLogin';
import useAxios from 'hook/useAxios';

import { ModalRequest } from './ModalRequest';

// events: [
//   {id:1, title: 'Влад', start: '2024-10-31T08:00:00', end: '2024-10-31T18:00:00', classNames: '_yel'},
//   {id:2, title: 'Оксана', start: '2024-10-30T08:00:00', end: '2024-10-30T14:00:00', classNames: '_red'}
// ]

const Calendar = () => {

  const {user,position} = useLogin();
  const axios=useAxios(user);

  const [events,setEvents] = useState([]);
  const [calendarKey, setCalendarKey] = useState(0);
  const calendarRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({
    show:false,
    event:null
  });

  // mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      if (mobile !== isMobile) {
        setIsMobile(mobile);
        setCalendarKey(prevKey => prevKey + 1);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);
  const initialView = isMobile ? "listWeek" : 'timeGridWeek'
  const headerToolbar = isMobile
    ? { left: 'prev,next', center: 'title', right: '' }
    : { left: 'today,prev,next', center: 'title', right: 'timeGridWeek'} // dayGridMonth


  //* event click open modal
  const handleEventClick = (info) => {
    setModal({show:true,event:info.event});
  };

  const refreshCalendar = () =>{
    const reqUrl = `/dashboard/work`;
    setLoading(true);

    axios
    .get(reqUrl)
    .then(({data}) => {
        if(data.res){
            setEvents(data.res)
            setLoading(false);
        }
    })
    .catch((err) => {
        console.error("Error:", err);
    });
  }

  // request
  const sendRequest = (id) =>{
    const reqData = {
      id_user:user.id,
      id_time:id
    }
    const reqUrl = `/dashboard/request`;
    setLoading(true);

    axios
    .post(reqUrl,reqData)
    .then(({data}) => {
        if(data.error){
          toast.error(data.error);
        }else{
          toast.success(data.res);
        }
        setLoading(false);
    })
    .catch((err) => {
        console.error("Error:", err);
    });
  }

  // start
  useEffect(()=>{
    setLoading(true);
    refreshCalendar();
  },[position])

  const renderEventContent = (info) => {
    const isTimeGridView = info.view.type === 'timeGridWeek' || info.view.type === 'timeGridDay'; // Перевірка вигляду
    if (isTimeGridView) { 
        const user = info.event.extendedProps.user;
        const startTime = info.event.start.toLocaleTimeString([], { 
          hour: '2-digit', 
          minute: '2-digit',
          timeZone: 'Europe/Kyiv'
        });
        const endTime = info.event.end.toLocaleTimeString([], { 
          hour: '2-digit', 
          minute: '2-digit',
          
        });
        return (
            <div>
                <div>{startTime} - {endTime}</div>
                {user ? <div><strong>{user.name}</strong></div> : ''}
            </div>
        );
    }else{
        return (
            <div>
                <div>{user.name}</div>
            </div>
        );
    }
  };

  return (
    <Container>
      {loading && (
        <div
          style={{
            position: 'fixed',
            inset:0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1050,
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="dark" />
        </div>
      )}
      <FullCalendar
        key={calendarKey}
        ref={calendarRef} 
        height="540px"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        locales={[ukLocale]}
        locale="uk"
        timeZone="Europe/Kiev"
        initialView={initialView}
        selectable={false}
        editable={false}
        slotMinTime="08:00:00"
        slotMaxTime="21:00:00"
        slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
        }}
        allDaySlot={false}
        headerToolbar={headerToolbar}
        dayHeaderContent={(args) => {
            // Отримуємо номер дня та повну назву дня
            const dayNumber = args.date.getDate();
            const dayName = args.date.toLocaleDateString('uk-UA', { weekday: 'long' });
  
            return (
              <div className="custom-header">
                <div className="day-number">{dayNumber}</div>
                <div className="day-name">{dayName}</div>
              </div>
            );
          }}
        events={events}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
      />
      <ToastContainer 
        position="bottom-right"
        theme="light"
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={3000}
      />

      {modal.event && <ModalRequest modal={modal} setModal={setModal} sendRequest={sendRequest}/>}

      {/* <Modal show={showModal} onHide={handleCloseModal} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Редагування події</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEventTitle">
              <Form.Label>Назва події</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <SelectInput
              placeholder="Вибрати працівника"
              data={data.route}
              setData={({ label }) => setData({ ...data, route: label })}
              options={route}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Скасувати
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Зберегти зміни
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );

};

const Container = styled.div`
    border-radius:20px;
    background:#fff;
    box-shadow:0 0 20px rgba(0,0,0,.2);
    padding:20px;
`

export default Calendar;