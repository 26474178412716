import Calendar from "./AccountAdmin/Calendar"
import { Message } from "./AccountAdmin/Message"
// import { useState } from "react"
// import Modal from "./Modal"
import { useLogin } from "hook/useLogin"
import { MenuBuild } from "./AccountAdmin/Menu/MenuBuild"
import { MenuPosition } from "./AccountAdmin/Menu/MenuPosition"
import { MenuUsers } from "./AccountAdmin/Menu/MenuUsers"

const AccountAdmin = () =>{

    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const handleOpenModal = () => setIsModalOpen(true);
    // const handleCloseModal = () => setIsModalOpen(false);
    const {build,position,signOut} = useLogin();

    // console.log(build);return false;

    return (
        <>
            {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <h5>Ви бажаєте відправити запит на відсутність ?</h5>
                <div className="d-flex justify-content-between mt-3">
                    <div className="button">Відправити</div>
                    <div className="button _none" onClick={()=>handleCloseModal()}>Ні</div>
                </div>
            </Modal> */}

            <div className="main">
                <div>
                    <h1>XLIB&COFFEE</h1>
                </div>
                <Message/>
                <div className="d-none d-md-flex justify-content-between align-items-center">
                    <div className="d-flex gap-5">
                        <MenuBuild/>
                        {build.selected?.id && <MenuPosition/>}
                    </div>
                    <div className="d-flex gap-3">
                        <div>
                            <MenuUsers/>
                        </div>
                        <div className="icons _exit" onClick={()=>signOut()}></div>
                    </div>
                </div>
                {position.selected?.id 
                    ? <Calendar/> 
                    : <div className="calendar-empty">
                        <img src="/img/worker.png"/>
                        <div>Посада не вибрана</div>
                    </div>
                }
            </div>
        </>
    )
}

export {AccountAdmin}